.walletConnect {
    float: right;
}
.stakeTokenContainer {
    width: 100%;
}

.balancesContainer {
    display: flex;
    align-items: stretch;
    width: 100%;
    gap: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
}