.box {
    /*border: 1px solid black;*/
    padding: 1rem;
    flex: 2;
    border-radius: 1.5rem;
    box-shadow: 0px 0px 2px #000000;

}

.tokenBalance {
    /*background-color: blue;*/
}